@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth
  }
  body{
    @apply font-body;
  }
  h1, h2, h3, h4, h5, h6 {
    @apply font-body;
  }

  h1 {
    @apply font-black text-7xl;
  }

  h2 {
    @apply font-black text-6xl;
  }

  h3 {
    @apply font-bold text-xl;
  }
  .tab{
    margin-top: -100px;
    padding-bottom: 100px;
    display: block;
  }

  .header {
    background: url('../public/assets/images/hero-patern.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .violet_gradient{
    background: -webkit-linear-gradient(-90deg,#804dee 0%,rgba(60,51,80,0) 100%);
  }
  .green_gradient{
    background: -webkit-linear-gradient(-90.13deg,#00cea8 1.9%,#bf61ff 97.5%);  
  }
  .shadow-card {
    --tw-shadow: 0px 35px 120px -15px #211e35;
    --tw-shadow-colored: 0px 35px 120px -15px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
}

  @keyframes bounce {
    0%{
      transform: translateY(0);
    }
    20%{
      transform: translateY(-3px);
    }
    50%{
      transform: translateY(30px);
    }
    70%{
      transform: translateY(-3px);
    }
    100%{
      transform: translateY(0);
    }

  }
  .scrollbar {
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #4a5568; /* Set the color of the scrollbar thumb */
      border-radius: 5px; /* Optional: Round the corners of the thumb */
  }

  ::-webkit-scrollbar-track {
      background-color: #cbd5e0; /* Set the color of the scrollbar track */
  }
  }
  .scroll {
    animation: bounce 2.6s infinite;
  }
  .timeline::before{
    content: "";
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #ffffff;
    left: 50%;
  }

  .project:nth-child(even){
    display: flex;
    justify-content: flex-end;
  }
  
  .date{
    position: absolute;
    left: 57%;
    top: 30px;
    font-size: 1rem;
  }
  .project:nth-child(even) .date{
    left: 31%;
    top: 30px;
  }
  .project:nth-child(even) .arrow{
    left: 57%;
  }
}

.download-button{
  background-color: #915eff;
  padding: .25rem 1rem;
  border-radius: 50px;    
  border: 3px solid #915eff;
  color: white;
  margin-top: 1rem;
  transition: .3s;
}
.download-button:hover{
  background-color: transparent; 
}